.Trimmer {
  position: absolute;
  width: 100%;
  height: 56px;
}

.TrimmerStart,
.TrimmerEnd {
  position: absolute;
  top: 2px;
  z-index: 1;
}

.TrimmerStart {
  border-radius: 4px 0 0 4px;
}

.TrimmerEnd {
  margin-left: -14px;
  border-radius: 0 4px 4px 0;
}

.TrimmerStart svg {
}

.TrimmerEnd svg {
}
