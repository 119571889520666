.TimelineHeader {
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
}

.StartTrimInputCont {
  width: 72px;
  height: 24px;
  display: flex;
  background: rgba(187, 187, 187, 0.3);
  border-radius: 4px;
  border: 0;
  padding: 0 4px;
}

.StartTrimInput {
  width: 100%;
  font-family: Inter, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  background: rgba(187, 187, 187, 0);
  border: 0;
  text-align: center;
  color: inherit;
}

.EndTrimInputCont {
  width: 72px;
  height: 24px;
  display: flex;
  background: rgba(187, 187, 187, 0.3);
  border-radius: 4px;
  border: 0;
  padding: 0 4px;
  color: inherit;
}

.EndTrimInput {
  width: 100%;
  font-family: Inter, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: inherit;
  background: rgba(187, 187, 187, 0);
  border: 0;
  text-align: center;
}
