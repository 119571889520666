.TimeLineUndoRedo {
  display: flex;
  user-select: none;
}

.TimeLineUndoRedo > :nth-child(n + 2) {
  margin-left: 8px;
}

.UndoButton {
  position: static;
  width: 24px;
  height: 24px;
  left: 0;
  top: 0;
  flex: none;
  order: 0;
  margin: 0 8px 0 0;
}

.UndoSVG {
  position: relative;
  width: 24px;
  height: 24px;
  left: -8px;
  top: -2px;
  align-self: center;
}

.UndoDisSVG {
  position: relative;
  width: 24px;
  height: 24px;
  left: -8px;
  top: -1px;
  align-self: center;
  background: #ffffff;
  border-radius: 4px;
  flex: none;
  order: 1;
  margin: -2px 0;
}

.RedoButton {
  position: static;
  width: 24px;
  height: 24px;
  left: 0;
  top: 0;
  flex: none;
  order: 0;
  margin: 0 8px 0 0;
}

.RedoSVG {
  position: relative;
  width: 24px;
  height: 24px;
  left: -8px;
  top: -2px;
  align-self: center;
}

.RedoDisSVG {
  position: relative;
  width: 24px;
  height: 24px;
  left: -8px;
  top: -1px;
  align-self: center;
  flex: none;
  order: 1;
  margin: -2px 0;
  border-radius: 4px;
}
