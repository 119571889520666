.PreviewAnimatedPulse {
  position: absolute;
  z-index: 2;
  left: 50%;
  top: 50%;
}

.PreviewVideoCanvas {
  display: flex;
  justify-content: center;
  align-items: center;
}

.PreviewTrimmStartButton {
  display: flex;
  padding: 4px;
  width: 123px;
  height: 32px;
  margin-right: 16px;
  background: #f28a21;
  border-radius: 4px;
  cursor: pointer;
}

.PreviewTrimmEndButton {
  display: flex;
  padding: 4px;
  width: 115px;
  height: 32px;
  background: #f28a21;
  border-radius: 4px;
  cursor: pointer;
}

.Progress {
  width: 100%;
}
