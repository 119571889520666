.TimeLineZoom {
  display: flex;
  user-select: none;
}

.TimeLineZoom > :nth-child(n + 2) {
  margin-left: 8px;
}

.TimeLineZoomIn {
  height: 24px;
  width: 80px;
  background: rgba(187, 187, 187, 0.3);
  border-radius: 4px;
}

.TimeLineZoomOut {
  height: 24px;
  background: rgba(187, 187, 187, 0.3);
  border-radius: 4px;
}

.TimeLineZoomFit {
  height: 24px;
  background: rgba(187, 187, 187, 0.3);
  border-radius: 4px;
}
