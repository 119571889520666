.TimelineTrackLine {
  display: flex;
  position: absolute;
  width: 4px;
  height: 63px;
  top: -4px;
  background: #00c853;
  flex: none;
  align-self: flex-start;
  margin: 0 0;
  border-radius: 4px;
  z-index: 10;
  user-select: none;
  transform: translateX(-50%);
}

.TimeLineTrackTimeStamp {
  display: flex;
  position: absolute;
  width: 100px;
  height: 20px;
  left: 0;
  top: 2px;
  background: #00c853;
  border-radius: 4px;
  flex: none;
  align-self: flex-start;
  justify-content: center;
  margin: 0 0;
  z-index: 11;
  user-select: none;
}

.TimeLineTrackTimeStamp span {
  width: 60px;
  font-family: Inter, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 9.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  user-select: none;
}

.TimeLineTrackTimeMenu {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 20px;
  width: 100px;
  z-index: 11;
}

.TimeLineTrackTimeMenu svg {
  margin-right: 7px;
  width: 11px;
}

.TimeLineTrackTimeMenu span {
  width: 67px;
  height: 16px;
  font-family: Inter, serif;
  font-style: normal;
  font-size: 10px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #ffffff;
  z-index: 11;
  user-select: none;
}

.TimeLineTrackTimeMenu_StartTrim {
  background-color: #00c853;
  padding-left: 10px;
  display: flex;
  align-items: center;
  z-index: 11;
  height: 17px;
  cursor: pointer;
}

.TimeLineTrackTimeMenu_StartTrim:hover {
  background-color: #0a9746;
  z-index: 11;
}

.TimeLineTrackTimeMenu_EndTrim {
  background-color: #00c853;
  padding-left: 10px;
  display: flex;
  align-items: center;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  z-index: 12;
  height: 17px;
  cursor: pointer;
}

.TimeLineTrackTimeMenu_EndTrim:hover {
  background-color: #0a9746;
  z-index: 11;
}

.VectorButton {
  width: 11px;
  height: 100%;
  margin-right: 7px;
  cursor: pointer;
}

.TrimmStartButton {
  width: 12px;
  height: 12px;
  z-index: 1000;
}

.TrimmEndButton {
  width: 12px;
  height: 12px;
  z-index: 1000;
}
