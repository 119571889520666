.DownloadButton {
  display: flex;
  padding: 4px;
  width: 110px;
  height: 32px;
  background: #f28a21;
  border-radius: 4px;
  margin-right: 16px;
  cursor: pointer;
}

.DownloadButtonDisabled {
  display: flex;
  padding: 4px;
  width: 110px;
  height: 32px;
  background: rgba(187, 187, 187, 0.3);
  border-radius: 4px;
  margin-right: 16px;
}
