.TimeLineTabs {
  display: flex;
  height: 32px;
  width: 100%;
  background-color: #ececec;
}

.TimeLineTracksArea {
  display: flex;
  width: 100%;
}
