.TrackLine {
  max-height: 56px;
  /*display: flex;*/
  flex-wrap: nowrap;
  background-color: #ffffff;
  top: 0;
  padding: 16px 0;
}

.Track {
  display: block;
  position: relative;
  margin: 0 auto;
  min-width: 1px;
  height: 56px;
  flex: 0 0 auto;
  border: 0 #00ff00 solid;
  background-repeat: no-repeat;
  border-radius: 4px;
  width: calc(100% - 48px);
  background-color: #ffffff;
}

.TrackVideo {
  display: none;
  width: 100px;
  height: 56px;
}
