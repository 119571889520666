.TracksAreaContainer {
  display: flex;
  scroll-behavior: smooth;
  width: 100%;
  flex-wrap: nowrap;
  overflow-y: hidden;
  flex-direction: column;
}

.TracksArea {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  flex-direction: column;
  overflow: hidden;
}
